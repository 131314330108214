.profile {
    text-align: center;

    img {
        width: 150px;
        height: 150px;
        border-radius: 50%;
    }
}

