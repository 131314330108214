.footer {

  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;

  .item {
    text-align: center;
    padding: 10px;

    a {
      text-decoration: none;

      .icon {
        margin-right: 5px;
      }

      img {
        border: none;
      }
    }
  }
}