.container {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;

  width: 400px;
  max-width: 100%;

  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .item {
    padding: 10px;

    a {
      text-decoration: none;
      color: #333;

      &:hover {
        color: dimgrey;
      }
    }

    img {
      max-width: 80px;
      max-height: 80px;
      border-radius: 50%;
    }

    span {
      display: block;
      text-align: center;
    };
  }
}